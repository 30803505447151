import React, { FC, Fragment } from "react";

import CustomChip from "@components/CustomChip/CustomChip";
import CustomChipWithTooltip from "@components/CustomChip/CustomChipWithToolTip";
import { ResourceValues } from "@interfaces/resource-values";
import { Stack } from "@mui/material";

interface PostTagsProps {
  post: ResourceValues;
}

const PostTags: FC<PostTagsProps> = ({ post }) => {
  const renderTags = post.tag_names ? (
    post.tag_names.length <= 4 ? (
      post.tag_names.map((tag, idx) => <CustomChip tag={tag} key={idx} />)
    ) : (
      <>
        {post.tag_names.slice(0, 4).map((tag, idx) => (
          <Fragment key={idx}>
            <CustomChip tag={tag} />
          </Fragment>
        ))}
        <CustomChipWithTooltip
          tag={`+ ${post.tag_names.length - 4}`}
          tooltipContent={post.tag_names
            .slice(4, post.tag_names.length)
            .join(", ")}
        />
      </>
    )
  ) : (
    <></>
  );

  return (
    <Stack
      direction='row'
      spacing={0.5}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >
      {renderTags}
    </Stack>
  );
};

export default PostTags;
