import getError from "@features/utils/get-error";
import User, { UpdateFormValues } from "@interfaces/user";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

const defaultUser: User = {
  id: "",
  firstName: "",
  lastName: "",
  institutionName: "",
  labName: "",
  aboutMe: "",
  linkedInUrl: "",
  googleScholarUrl: "",
  researchGateUrl: "",
  position: "",
  profileImage: "",
  researchInterests: [],
  labId: "",
  institutionId: ""
};

export const fetchUserProfile = createAsyncThunk(
  "profile/fetchUserProfile",
  async (userId: string, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.get(`/user?userId=${userId}`, {
        cancelToken: source.token
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updateProfile = createAsyncThunk(
  "proflie/updateProfile",
  async (values: UpdateFormValues, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.patch(`/user`, values, {
        cancelToken: source.token
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    user: defaultUser,
    status: "idle"
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "succeeded";
      })
      .addCase(updateProfile.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const { name, actions } = profileSlice;

/**
 * Selector function to get the profile slice from the Redux state.
 *
 * @param {RootState} state - The root Redux state.
 * @returns {InitialState} - The profile slice from the Redux state.
 */
const getSlice = (state: RootState) => state[name];

//Selector: Get the loading state.
export const getUserProfile = createSelector(
  getSlice,
  (slice) => slice?.user || defaultUser
);

export const getProfileStatus = createSelector(
  getSlice,
  (slice) => slice?.status || "idle"
);
