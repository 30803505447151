import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "@features/Auth/authSlice";
import User from "@interfaces/user";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import DefaultProfile from "@static/icons/default-profile.png";
import logoutSVG from "@static/icons/logoutGreen.svg";
import { AppDispatch } from "src/services/store";

interface UserBlockProps {
  currentUser: User;
}

const UserBlock: React.FC<UserBlockProps> = ({ currentUser = {} }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async (event: React.MouseEvent) => {
    event.preventDefault();

    const logoutResponse = await dispatch(logout());
    if (logoutResponse.payload.logoutUrl) {
      return (window.location.href = logoutResponse.payload.logoutUrl);
    }

    navigate("/");
  };

  return (
    <Box px={3} mb={3}>
      <Stack direction={"row"} gap={1} px={1} justifyContent={"space-between"}>
        <Box
          position={"relative"}
          display='inline-block'
          height={"min-content"}
        >
          <Avatar
            sx={{ width: 55, height: 55 }}
            src={currentUser.avatar || DefaultProfile}
          />
          <Box
            position='absolute'
            bottom={0}
            right={0}
            width={15}
            height={15}
            bgcolor='green'
            borderRadius='50%'
            border='2px solid white' // white border to separate the circle from the avatar
          />
        </Box>
        <Stack>
          <Stack gap={0.1} sx={{ maxWidth: "120px" }}>
            <Typography
              fontWeight={700}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {currentUser.firstName} {currentUser.lastName}
            </Typography>
            <Typography
              mb={1}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
              }}
            >
              {currentUser.email}
            </Typography>
          </Stack>
          <Button
            size='small'
            variant='outlined'
            href='https://forms.gle/Lz1R9RTdys8upTjF8'
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              borderRadius: 3,
              px: 1
            }}
          >
            Share Feedback
          </Button>
        </Stack>
        <Box>
          <IconButton onClick={handleLogout}>
            <img
              src={logoutSVG}
              alt='logout'
              style={{ width: 20, height: 20 }}
            />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default UserBlock;
