import React, { ComponentType, FC } from "react";

import { Box, Tooltip } from "@mui/material";

export interface WithTooltipProps {
  tooltipContent?: string;
}

// Higher-Order Component to add a tooltip
export default function withTooltip<T extends WithTooltipProps>(
  Component: ComponentType<T>
) {
  const WithTooltipComponent: FC<T & WithTooltipProps> = (props) => {
    const { tooltipContent, ...restProps } = props as WithTooltipProps;
    return (
      <Tooltip arrow title={tooltipContent} placement='top'>
        <Box sx={{ cursor: "pointer" }}>
          <Component {...(restProps as T)} />
        </Box>
      </Tooltip>
    );
  };

  return WithTooltipComponent;
}
