import { useMemo } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation, useSearchParams } from "react-router-dom";

import { getUser } from "@features/Auth/authSlice";

const useMatchPath = (path = "/") => {
  const { pathname } = useLocation();

  const user = useSelector(getUser);

  const [params] = useSearchParams();

  return useMemo(() => {
    // get the user's id and match against the lab id in the params
    const lab_id = params.get("lab_id");

    if (path.includes("/dashboard/lab")) {
      if (lab_id) {
        return lab_id === user.labId;
      }
    }

    return Boolean(matchPath(pathname, path));
  }, [path, pathname, user, params.get("lab_id")]);
};

export default useMatchPath;
