import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUser } from "@features/Auth/authSlice";
import { RootState } from "src/services/store";

interface Options {
  redirect: boolean;
}

const useRequireSession = (options: Options) => {
  const { redirect = true } = options;
  const user = useSelector((state: RootState) => getUser(state));
  const navigate = useNavigate();

  const { id } = user;
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      return;
    }

    if (redirect) {
      navigate(`/get-started?redirect=${window?.location?.pathname ?? ""}`);
    }
  }, [dispatch, redirect, id]);

  return useMemo(() => [id], [id]);
};

export default useRequireSession;
