import React, { FC, ReactNode } from "react";

import Footer from "@components/Footer/Footer";

interface LayoutWithFooterProps {
  children: ReactNode;
}

const LayoutWithFooter: FC<LayoutWithFooterProps> = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh"
    }}
  >
    <main style={{ flex: 1 }}>{children}</main>
    <Footer />
  </div>
);

export default LayoutWithFooter;
