import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import { useSearchParams } from "react-router-dom";
interface RedirectContextProps {
  fromGetStarted: boolean;
  setFromGetStarted: (value: boolean) => void;
}

const RedirectContext = createContext<RedirectContextProps | undefined>(
  undefined
);

export const useRedirect = () => {
  const context = useContext(RedirectContext);

  if (!context) {
    throw new Error("useRedirect must be used within a RedirectProvider");
  }

  const [params] = useSearchParams(window.location.search);

  useEffect(() => {
    const fromGetStartedParam = params.get("fromGetStarted");
    if (fromGetStartedParam === "true") {
      context.setFromGetStarted(true);
    }
  }, [context, params]);

  return context;
};

export const RedirectProvider = ({ children }: { children: ReactNode }) => {
  const [fromGetStarted, setFromGetStartedState] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("fromGetStarted");

    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    localStorage.setItem("fromGetStarted", JSON.stringify(fromGetStarted));
  }, [fromGetStarted]);

  const setFromGetStarted = (value: boolean) => {
    setFromGetStartedState(value);
  };

  return (
    <RedirectContext.Provider value={{ fromGetStarted, setFromGetStarted }}>
      {children}
    </RedirectContext.Provider>
  );
};
