export const removeNullValues = <T extends object>(obj: T): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !!value)
  ) as Partial<T>;
};

export const replaceNullWithString = <T extends object>(obj: T): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (!value) return [key, ""];
      return [key, value];
    })
  ) as Partial<T>;
};
