import React, { FC, ReactNode } from "react";

import clsx from "@features/utils/clsx";

interface DashboardBodyProps {
  children: ReactNode;
  className?: string;
}

const DashboardBody: FC<DashboardBodyProps> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "flex flex-col w-0 flex-1 overflow-hidden bg-gray-100",
        className
      )}
    >
      {children}
    </div>
  );
};

export default DashboardBody;
