// Helper function to format the relative time
const FormatRelativeTime = (dateString: string): string => {
  const createdDate = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor(
    (now.getTime() - createdDate.getTime()) / 1000
  );

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInWeek = 7 * secondsInDay;
  const secondsInMonth = 30 * secondsInDay;
  const secondsInYear = 365 * secondsInDay;

  if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return `${minutes}${minutes === 1 ? "m" : "m"}`;
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return `${hours}${hours === 1 ? "h" : "h"}`;
  } else if (diffInSeconds < secondsInWeek) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days}${days === 1 ? "d" : "d"}`;
  } else if (diffInSeconds < secondsInMonth) {
    const weeks = Math.floor(diffInSeconds / secondsInWeek);
    return `${weeks}${weeks === 1 ? "wk" : "wks"}`;
  } else if (diffInSeconds < secondsInYear) {
    const months = Math.floor(diffInSeconds / secondsInMonth);
    return `${months}mo`;
  } else {
    const years = Math.floor(diffInSeconds / secondsInYear);
    return `${years}${years === 1 ? "yr" : "yrs"}`;
  }
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const formatDateJoined = (dateJoined: string | Date) => {
  const date = new Date(dateJoined);

  const month = date.getMonth();
  const year = date.getFullYear();

  const monthName = months[month];

  return `${monthName} ${year}`;
};

export default FormatRelativeTime;
