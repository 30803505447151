import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContactFormDialog from "@components/Dialogs/ContactFormDialog";
import { getUser } from "@features/Auth/authSlice";
import { deleteResource } from "@features/InsitutionDatabase/resourceSlice";
import clsx from "@features/utils/clsx";
import FormatRelativeTime from "@features/utils/formatTime";
import { handleShare } from "@features/utils/handleShare";
import { renderStrainName } from "@features/utils/render-strain-name";
import { PostType } from "@interfaces/post";
import { ResourceValues } from "@interfaces/resource-values";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import DefaultProfile from "@static/icons/default-profile.png";
import globe from "@static/icons/globe.svg";
import share from "@static/icons/share-dark.svg";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import PostTags from "./PostTags";

interface EntryProps {
  key: string;
  post: ResourceValues;
}

export default function Entry({ post }: EntryProps) {
  // Set up code for the "actions" menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openActionMenu = Boolean(anchorEl);
  const handleClickActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  // Set up code for delete confirmation
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleClickDelete = () => {
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };
  const dispatch: AppDispatch = useDispatch();

  const handleDeleteRequest = () => {
    const postId = post.id as string;
    dispatch(deleteResource(postId));
    handleDeleteModalClose();
  };

  // Set up code for contact form dialog
  const currentUser = useSelector(getUser);

  const authorLoggedIn = post.contact_user_details?.user_id === currentUser.id;
  const [openContactForm, setOpenContactForm] = React.useState(false);
  const handleOpenContactForm = () => {
    setOpenContactForm(true);
  };

  const handleCloseContactForm = () => {
    setOpenContactForm(false);
  };

  const authorDisplayName = `${post.contact_user_details?.first_name || ""} ${
    post.contact_user_details?.last_name || ""
  }`;
  const createdDate = post.created_at as string;

  const backgroundClass =
    post.source === PostType.USER_SHARE
      ? `bg-[#f5dd9d] text-black`
      : `bg-[#ffa098] text-white`;

  // Manage read more / read less state
  const [isTruncated, setIsTruncated] = useState(true);
  const maxLength = 500;

  const handleToggleReadMore = () => {
    setIsTruncated(!isTruncated);
  };

  // Truncate the description if it's too long
  const truncatedDescription =
    post.description && post.description.length > maxLength
      ? post.description.substring(0, maxLength) + "..."
      : post.description;

  return (
    <Box>
      <Card variant='outlined' sx={{ borderRadius: "10px" }}>
        <CardHeader
          sx={{ px: 3 }}
          avatar={
            <Avatar
              sx={{
                bgcolor: "secondary.main",
                width: "70px",
                height: "70px",
                objectFit: "cover",
                "& .MuiCardHeader-action": {
                  alignSelf: "center",
                  margin: 0
                }
              }}
              src={DefaultProfile}
            />
          }
          title={
            <Stack>
              <Link
                href={`/dashboard/member/${post.contact_user_details?.user_id}`}
                underline='hover'
                fontSize={17}
                color={APP_COLORS.black}
              >
                <strong>{authorDisplayName}</strong>
              </Link>
              <Typography>
                {post.contact_user_details?.institution_name}
              </Typography>
            </Stack>
          }
          subheader={
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <Box
                component={"img"}
                src={globe}
                height={18}
                width={18}
                sx={{ objectFit: "cover" }}
              />
              <Typography fontSize={14}>
                {FormatRelativeTime(createdDate)}
              </Typography>
            </Stack>
          }
          action={
            authorLoggedIn ? (
              <Box
                sx={{ display: "flex", alignItems: "center", height: "100%" }}
              >
                <IconButton
                  aria-label='settings'
                  onClick={handleClickActionMenu}
                  aria-expanded={openActionMenu ? "true" : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            ) : null
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={openActionMenu}
          onClose={handleCloseActionMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <MenuItem onClick={handleClickDelete}>Delete post</MenuItem>
        </Menu>
        <Dialog open={openDeleteModal} onClose={handleDeleteModalClose}>
          <DialogTitle>Are you sure you want to delete this post?</DialogTitle>
          <DialogContent>
            <Typography>This action cannot be undone.</Typography>
            <DialogActions>
              <Button variant='contained' onClick={handleDeleteRequest}>
                Confirm
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Divider />
        <CardContent sx={{ py: "10px", px: 3 }}>
          <Stack pl={0.2} spacing={3} mb={2}>
            <Stack spacing={1}>
              {post.title ? (
                <Typography fontSize={"1.1rem"} fontWeight={500}>
                  {renderStrainName(post.title)}
                </Typography>
              ) : (
                <></>
              )}
              <Stack direction={"row"} gap={1}>
                <div
                  className={clsx(
                    "font-semiBold rounded-full py-1 px-5 text-xs flex items-center justify-center border border-2 border-transparent",
                    backgroundClass
                  )}
                >
                  {post.source === PostType.USER_SHARE ? "Share" : "Request"}
                </div>
                {post.category ? (
                  <div
                    className={clsx(
                      "font-semiBold border border-[#209498] capitalize py-1 px-5 text-xs flex items-center justify-center rounded-full"
                    )}
                  >
                    {post.category.split("_").join(" ")}
                  </div>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
            {post.description ? (
              <Typography fontSize={"1rem"}>
                {renderStrainName(
                  isTruncated ? truncatedDescription : post.description
                )}
                {post.description.length > maxLength && (
                  <Button onClick={handleToggleReadMore} sx={{ ml: 1 }}>
                    {isTruncated ? "Read more" : "Read less"}
                  </Button>
                )}
              </Typography>
            ) : (
              <></>
            )}
            <Stack>
              {post.name ? (
                <Typography component={"small"} fontSize={"0.8rem"}>
                  <strong>
                    {post.category === "cell_line"
                      ? "Cell Line Name: "
                      : "Strain Name: "}
                  </strong>
                  {renderStrainName(post.name)}
                </Typography>
              ) : (
                <></>
              )}

              {post.species_name ? (
                <Typography component={"small"} fontSize={"0.8rem"}>
                  <strong>Species: </strong>
                  {post.species_name}
                </Typography>
              ) : (
                <></>
              )}
              {post.jax_external_id ? (
                <Typography component={"small"} fontSize={"0.8rem"}>
                  <strong>JAX ID: </strong>
                  <Link
                    color={APP_COLORS.black}
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                    href={`https://www.jax.org/strain/${post.jax_external_id}`}
                  >
                    {post.jax_external_id}
                  </Link>
                </Typography>
              ) : (
                <></>
              )}
              {post.mgi_external_id ? (
                <Typography component={"small"} fontSize={"0.8rem"}>
                  <strong>MGI ID: </strong>
                  <Link
                    color={APP_COLORS.black}
                    target='_blank'
                    rel='noreferrer'
                    className='underline'
                    href={`https://www.informatics.jax.org/strain/MGI:${post.mgi_external_id}`}
                  >
                    {post.mgi_external_id}
                  </Link>
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2
              }}
            >
              <PostTags post={post} />
            </Box>
          </Stack>
        </CardContent>
        <Divider />
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
          py={2}
          px={3}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, md: "none" }
            }}
          >
            <Button
              sx={{
                borderRadius: 12,
                borderColor: APP_COLORS.buttonGray,
                color: APP_COLORS.buttonGrayText,
                "&:hover": {
                  borderColor: APP_COLORS.darkGray,
                  color: APP_COLORS.darkGray
                }
              }}
              color='primary'
              size='small'
              variant='outlined'
              onClick={() => handleShare(post.id as string)}
            >
              <Typography fontSize='0.8rem'>Share</Typography>
              <Box
                component={"img"}
                src={share}
                width={"15px"}
                height={"15px"}
                ml={1}
              />
            </Button>
            {!authorLoggedIn ? (
              <Button
                sx={{
                  borderRadius: 12,
                  borderColor: APP_COLORS.buttonGray,
                  color: APP_COLORS.buttonGrayText,
                  "&:hover": {
                    borderColor: APP_COLORS.darkGray,
                    color: APP_COLORS.darkGray
                  }
                }}
                color='primary'
                size='small'
                variant='outlined'
                onClick={handleOpenContactForm}
              >
                <Typography fontSize='0.8rem'>Contact</Typography>
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Card>
      <ContactFormDialog
        openContactForm={openContactForm}
        handleCloseContactForm={handleCloseContactForm}
        postId={post.id}
        toUserId={post.contact_user_details?.user_id}
        authorDisplayName={authorDisplayName}
      />
    </Box>
  );
}
