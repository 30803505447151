import React, { FC, ReactNode } from "react";

import { Transition } from "@headlessui/react";
import useSidebar from "@hooks/useSidebar";
import { HighlightOffOutlined } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Stack } from "@mui/material";
import { APP_COLORS } from "src/styles/colors";
import { ShipporiTypography } from "src/styles/components";

interface Actions {
  on: () => void;
  off: () => void;
  toggle: () => void;
}

interface DashboardSidebarMobileProps {
  children: ReactNode;
  disableUserCard?: boolean;
  disableSecondaryNavigation?: boolean;
  institutionName?: string;
  //eslint-disable-next-line
  loading: any;
}

const DashboardSidebarMobile: FC<DashboardSidebarMobileProps> = ({
  children,
  institutionName,
  loading
}) => {
  const [sidebarActive, sidebarActions] = useSidebar();

  return (
    <div className='md:hidden'>
      {!sidebarActive && (
        <IconButton
          onClick={(sidebarActions as Actions).toggle}
          sx={{
            position: "fixed",
            top: { xs: "57px", sm: "65px" },
            left: 0,
            zIndex: 999,
            backgroundColor: APP_COLORS.white,
            border: `1px solid ${APP_COLORS.primaryMain}`,
            borderRadius: 1
          }}
        >
          <MoreIcon color='primary' />
        </IconButton>
      )}
      <Transition
        show={sidebarActive as boolean}
        className='fixed inset-0 mt-[57px] sm:mt-[65px] flex z-40'
      >
        <Transition.Child
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          className='fixed inset-0'
        >
          <div className='absolute inset-0 bg-gray-600 opacity-75' />
        </Transition.Child>
        <Transition.Child
          enter='transition ease-in-out duration-300 transform'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'
          className='relative flex-1 flex flex-col w-full bg-white'
        >
          <div className='shrink-0 flex border-b border-gray-300 justify-around items-start pt-3'>
            {/* We will show the institution's logo here */}
            <Stack>
              <Box
                component='div'
                sx={{
                  display: {
                    xs: "block",
                    md: "none"
                  },
                  textAlign: "center",
                  width: "100%",
                  whiteSpace: "normal",
                  overflowWrap: "break-word"
                }}
              >
                {loading.main ? (
                  <ShipporiTypography
                    textTransform={"uppercase"}
                    fontSize={{ base: "14px", md: "22px", lg: "32px" }}
                    fontWeight={600}
                    textAlign={"center"}
                  >
                    Loading... University
                  </ShipporiTypography>
                ) : (
                  <ShipporiTypography
                    textTransform={"uppercase"}
                    fontSize={{ base: "14px", md: "22px", lg: "32px" }}
                    fontWeight={600}
                    textAlign={"center"}
                  >
                    {institutionName}{" "}
                  </ShipporiTypography>
                )}
              </Box>
              <Box mb={5} mt={2} px={2}>
                <ShipporiTypography
                  textTransform={"uppercase"}
                  fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  RESOURCE SHARING PORTAL
                </ShipporiTypography>{" "}
              </Box>
            </Stack>
            <button
              onClick={(sidebarActions as Actions).toggle}
              className='flex items-center justify-center w-16 text-gray-500 focus:outline-none focus:text-gray-100 md:hidden'
              aria-label='Close sidebar'
            >
              <HighlightOffOutlined className='h-6 w-6 text-gray-500' />
            </button>
          </div>
          <div className='flex-1 h-0 pt-3 pb-3 overflow-y-auto'>
            {children}
            <div className='mx-6 my-3 border-b border-gray-100' />
          </div>
        </Transition.Child>
      </Transition>
    </div>
  );
};

export default DashboardSidebarMobile;
