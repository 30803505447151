import React, { FC, Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";

import { selectInstutionName, selectLabLoading } from "@features/Lab/labslice";

import DashboardSidebarDesktop from "./DashboardSidebarDesktop";
import DashboardSidebarMobile from "./DashboardSidebarMobile";

interface DashboardSidebarProps {
  children: ReactNode;
  disableUserCard?: boolean;
  disableSecondaryNavigation?: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = ({
  children,
  disableUserCard = false,
  disableSecondaryNavigation = false
}) => {
  const institutionName = useSelector(selectInstutionName);
  const loading = useSelector(selectLabLoading);
  return (
    <Fragment>
      <DashboardSidebarMobile
        disableUserCard={disableUserCard}
        disableSecondaryNavigation={disableSecondaryNavigation}
        institutionName={institutionName}
        loading={loading}
      >
        {children}
      </DashboardSidebarMobile>
      <DashboardSidebarDesktop
        institutionName={institutionName}
        loading={loading}
      >
        {children}
      </DashboardSidebarDesktop>
    </Fragment>
  );
};

export default DashboardSidebar;
