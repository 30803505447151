import getError from "@features/utils/get-error";
import { FormValues, InitialRegisterState } from "@interfaces/register";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

const initialState: InitialRegisterState = {
  firstName: "",
  lastName: "",
  institution: "",
  labName: "",
  email: "",
  password: "",
  confirmPassword: "",
  loading: {
    getStarted: false,
    register: false
  },
  // this is to keep track of the action property returned by the server (used in get-started) page
  action: null,
  error: ""
};

type FieldNames = keyof InitialRegisterState;

// register page submit button
export const register = createAsyncThunk(
  "register/registerUser",
  async (userData: FormValues, { signal, rejectWithValue }) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await api.post("/register", userData, {
        cancelToken: source.token
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    resetAction: (state) => {
      state.action = null;
    },
    updateField: (
      state,
      action: PayloadAction<{ field: FieldNames; value: string }>
    ) => {
      const { field, value } = action.payload;
      (state[field] as string) = value;
    },
    resetRegistrationForm: () => initialState,
    loadGetStarted: (state, action: PayloadAction<boolean>) => {
      if (state.loading) {
        state.loading.getStarted = action.payload;
      } else {
        // Initialize if not available
        state.loading = { getStarted: action.payload, register: false };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading.register = true;
      })
      .addCase(register.fulfilled, (state) => {
        state.loading.register = false;
      })
      .addCase(register.rejected, (state) => {
        state.loading.register = false;
      });
  }
});

export const { name, actions } = registerSlice;

export const {
  updateField,
  resetRegistrationForm,
  resetAction,
  loadGetStarted
} = actions;

export default registerSlice.reducer;

/**
 * Selector function to get the register slice from the Redux state.
 *
 * @param {RootState} state - The root Redux state.
 * @returns {InitialState} - The auth slice from the Redux state.
 */
const getSlice = (state: RootState) => state[name];

export const getLoading = createSelector(
  getSlice,
  (slice) => slice?.loading || { getStarted: false, register: false }
);

export const getAction = createSelector(
  getSlice,
  (slice) => slice?.action || null
);

export const getEmail = createSelector(getSlice, (slice) => slice?.email);
