export const constants = {
  animalModel: "animal_model",
  animal_models: "animal_models",
  cellLine: "cell_line",
  institutionalDBSources:
    "user_post_share,lab_member_upload,super_admin_upload",
  homeResource: "user_post_share,user_post_request",
  labUpload: "lab_member_upload",
  home: "home",
  profile: "profile",
  institution: "institution"
};
