import { useEffect, useRef } from "react";

interface UseInfiniteScrollProps {
  scrollContainer?: Element | null;
  shouldScroll?: () => boolean;
  onLoad: () => void;
  offset?: number;
  debounceTime?: number;
}

const useInfiniteScroll = ({
  scrollContainer,
  shouldScroll = () => true,
  onLoad,
  offset = 200,
  debounceTime = 300
}: UseInfiniteScrollProps) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const updateOnScroll = useRef<() => void>(() => {});
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  updateOnScroll.current = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      const scrollTop = scrollContainer
        ? scrollContainer.scrollTop
        : document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = scrollContainer
        ? scrollContainer.clientHeight
        : window.innerHeight;
      const scrollHeight = scrollContainer
        ? scrollContainer.scrollHeight
        : document.documentElement.scrollHeight || document.body.scrollHeight;
      const isScrolled = scrollTop + clientHeight >= scrollHeight - offset;
      if (isScrolled && shouldScroll()) {
        onLoad();
      }
    }, debounceTime);
  };

  useEffect(() => {
    const target = scrollContainer || window;
    const onScroll = () => updateOnScroll.current();

    target.addEventListener("scroll", onScroll);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      target.removeEventListener("scroll", onScroll);
    };
  }, [scrollContainer]);
};

export default useInfiniteScroll;
