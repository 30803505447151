import getError from "@features/utils/get-error";
import User from "@interfaces/user";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

export const getCurrentUser = createAsyncThunk(
  "getCurrentUser",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.get("/current_user", {
        cancelToken: source.token
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.post("/logout", {
        cancelToken: source.token
      });
      if (response.data.data?.logout_url) {
        // For Columbia users, return the logout URL
        return {
          ...response.data,
          isColumbia: true,
          logoutUrl: response.data.data.logout_url
        };
      } else {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

interface InitialState {
  loading: boolean;
  error: unknown;
  user: User;
  logoutUrl: string;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  user: {} as User,
  logoutUrl: ""
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: { type: string; payload: User }) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = {} as User;
        if (action.payload.isColumbia) {
          state.logoutUrl = action.payload.logouturl;
        }
      });
  }
});

export const { name, actions } = auth;

export const { setCurrentUser } = actions;

/**
 * Selector function to get the auth slice from the Redux state.
 *
 * @param {RootState} state - The root Redux state.
 * @returns {InitialState} - The auth slice from the Redux state.
 */
const getSlice = (state: RootState) => state[name];

//Selector: Get the loading state.
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

// Selector: Get the error state
export const getAuthError = createSelector(getSlice, (slice) => slice?.error);

export const getUser = createSelector(
  getSlice,
  (slice) => slice?.user || ({} as User)
);

export const getLogoutUrl = createSelector(
  getSlice,
  (slice) => slice?.logoutUrl || ""
);
