import React, { ReactElement } from "react";

import { WithTooltipProps } from "@components/hocs/withToolTip";
import Chip from "@mui/material/Chip";
import { APP_COLORS } from "src/styles/colors";

interface CustomChipProps extends WithTooltipProps {
  tag: string | ReactElement;
  avatar?: ReactElement;
  [x: string]: unknown | { sx: Record<string, unknown> };
}
const CustomChip: React.FC<CustomChipProps> = ({ tag, ...props }) => {
  return (
    <Chip
      label={tag}
      size='small'
      {...props}
      sx={{
        backgroundColor: APP_COLORS.backgroundLight,
        border: `1px solid ${APP_COLORS.mediumGray}`,
        color: APP_COLORS.darkGray,
        padding: "4px",
        fontSize: 12,
        ...(props?.sx as { sx: Record<string, unknown> })
      }}
    />
  );
};

export default CustomChip;
