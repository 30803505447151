export enum PostType {
  SHARE = "share",
  REQUEST = "request",
  USER_SHARE = "user_post_share",
  USER_REQUEST = "user_post_request"
}
export interface Post {
  id?: string;
  title?: string;
  created_at?: string | VarDate;
  type?: PostType;
  category?: string;
  description?: string;
  tag_names?: string[];
}

export type RenderSource = "home" | "profile" | "institution";

export default Post;
