import React, { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { getCurrentUser } from "@features/Auth/authSlice";
import { getLoading, getUser } from "@features/Auth/authSlice";
import User from "@interfaces/user";
import { AppDispatch, RootState } from "src/services/store";

interface ProtectedRouteProps {
  element: ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ element }) => {
  const dispatch: AppDispatch = useDispatch();
  const user: User = useSelector((state: RootState) => getUser(state));
  const loading: boolean = useSelector((state: RootState) => getLoading(state));

  useEffect(() => {
    if (!user.id) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, user.id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user.id ? <>{element}</> : <Navigate to='/get-started' replace />;
};

export default ProtectedRoute;
