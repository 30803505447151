/* eslint-disable no-cond-assign */
interface MixParam {
  mix: string | number | Record<string, unknown>;
  [key: string]: unknown;
}

function toVal(mix: MixParam) {
  let k,
    y,
    str = "";

  if (typeof mix === "string" || typeof mix === "number") {
    str += mix;
  } else if (typeof mix === "object") {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix[k]) {
          str && (str += " ");
          str += k;
        }
      }
    }
  }

  return str;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clsx(...args: any[]) {
  let i = 0,
    tmp,
    x,
    str = "";
  while (i < args.length) {
    // eslint-disable-next-line prefer-rest-params
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += " ");
        str += x;
      }
    }
  }
  return str;
}

export default clsx;
