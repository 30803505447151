import getError from "@features/utils/get-error";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

interface DonateState {
  // eslint-disable-next-line
  donation: Record<string, any>;
  error: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: DonateState = {
  donation: {},
  status: "idle",
  error: null
};

export const createDonation = createAsyncThunk(
  "donate/createDonation",
  async (
    // eslint-disable-next-line
    normalizedValues: Record<string, any>,
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.post("/transfer", normalizedValues, {
        cancelToken: source.token
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const donateSlice = createSlice({
  name: "donate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDonation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDonation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.donation = action.payload;
      })
      .addCase(createDonation.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const { name, actions } = donateSlice;

const getSlice = (state: RootState) => state[name];

export const selectDonation = createSelector(
  getSlice,
  (slice) => slice?.donation || initialState
);

export const selectDonationStatus = createSelector(
  getSlice,
  (slice) => slice.status
);
