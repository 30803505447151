import getError from "@features/utils/get-error";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

interface TagState {
  tags: string[];
  loading: boolean;
  error: unknown | null;
}

const initialState: TagState = {
  tags: [],
  loading: false,
  error: null
};

export const fetchTags = createAsyncThunk(
  "tags/fetchTags",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await api.get("/tags", {
        cancelToken: source.token
      });
      return response.data.tags.map((tag: { name: string }) => tag.name);
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    resetTags: (state) => {
      state.tags = [];
      state.loading = false;
      state.error = null;
    },
    setTags: (state, action) => {
      state.tags.push(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTags.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.tags = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchTags.rejected, (state, action: PayloadAction<unknown>) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

const { name, actions } = tagSlice;

export const { setTags } = actions;

export default tagSlice.reducer;

const getSlice = (state: RootState) => state[name];

export const getLoading = createSelector(getSlice, (slice) => slice.loading);

export const getTags = createSelector(getSlice, (slice) => slice.tags);
