import { AxiosError, isCancel } from "axios";

/**
 * Type guard to determine if a given error is an instance of AxiosError.
 *
 * @param error - The error to check.
 * @returns - `true` if the error is an instance of AxiosError, otherwise `false`.
 */
function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError === true;
}

type ErrorObject = {
  message: string;
  data?: unknown | Record<string, unknown>;
  status?: number;
};
export interface ResponseError {
  error: ErrorObject;
}
/**
 * Formats and retrieves the errors based on their type.
 *
 * - If the error was due to a canceled request, a generic message is returned.
 * - If it's an AxiosError, the data from the error response or an error object is returned.
 * - For all other errors, a generic "unknown error" message is returned.
 *
 * @param error - The error caught from Axios or any other error.
 * @returns - An object containing the error in a formatted manner.
 */
const getError = (error: unknown | AxiosError): ResponseError => {
  // Checking if the error is due to a cancel
  if (isCancel(error)) {
    const message =
      "There was an error sending your resquest. Please try again later.";
    return {
      error: {
        message
      }
    };
  }

  if (isAxiosError(error)) {
    return {
      error: {
        message: error.message,
        ...(error.response
          ? { status: error.response.status, data: error.response?.data }
          : {})
      }
    };
  }

  // Return generic object for other types of error
  return {
    error: {
      message: "An unknown error occurred."
    }
  };
};

export default getError;
