import React from "react";

export const renderStrainName = (strain: string | undefined) => {
  if (!strain) return "";
  // for content within angle brackets
  const regex = /<([^>]+)>/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  // Looping over matches and split the strain string
  while ((match = regex.exec(strain)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      parts.push(strain.substring(lastIndex, match.index));
    }
    // Add superscript part
    parts.push(<sup key={match.index}>{match[1]}</sup>);
    lastIndex = regex.lastIndex;
  }

  // Add remaining text after the last match
  if (lastIndex < strain.length) {
    parts.push(strain.substring(lastIndex));
  }

  return parts;
};
