import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useRedirect } from "@contexts/RedirectContext";

interface RedirectProtectedProps {
  element: ReactNode;
  requiresGetStarted?: boolean;
}

const RedirectProtected: FC<RedirectProtectedProps> = ({
  element,
  requiresGetStarted
}) => {
  const { fromGetStarted } = useRedirect();

  if (requiresGetStarted && !fromGetStarted) {
    return <Navigate to='/get-started' replace />;
  }

  return <>{element}</>;
};

export default RedirectProtected;
