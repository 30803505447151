import React, { createContext, lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./styles/index.css";

import TSAppBar from "@components/AppBar/TSAppBar";
import CreateButton from "@components/Buttons/CreateButton";
import ProtectedRoute from "@components/ProtectedRoute/ProtectedRoute";
import RedirectProtected from "@components/ProtectedRoute/RedirectProtected";
import { RedirectProvider } from "@contexts/RedirectContext";
import { Box, CssBaseline, styled, ThemeProvider } from "@mui/material";
import Portal from "@pages/InstitutionalPortal/Portal";
import { MaterialDesignContent, SnackbarProvider } from "notistack";

import { DashboardRoute } from "./layout/dashboard";
import LayoutWithFooter from "./layout/LayoutWithFooter";
import { theme } from "./styles/theme";

const TRACKING_ID = "G-JFRMYH6DYX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#fafafa",
    color: "#146981",
    overflowX: "hidden",
    wordBreak: "break-all"
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: "#fafafa",
    color: "#811414",
    overflowX: "hidden",
    wordBreak: "break-all"
  }
}));

createContext({});

const CreatePage = lazy(() => import("@features/Create/CreatePage"));
const PostPage = lazy(() => import("@features/Posts/PostPage"));
const ProfilePage = lazy(() => import("@features/Profile/ProfilePage"));
const AboutPage = lazy(() => import("@pages/About/AboutPage"));
const EmailVerificationPage = lazy(
  () => import("@pages/EmailVerificationPage")
);
const FAQPage = lazy(() => import("@pages/FAQs/FaqPage"));
const ForgotPasswordPage = lazy(() => import("@pages/ForgotPasswordPage"));
const GetStartedPage = lazy(() => import("@pages/get-started/GetStartedPage"));
const LandingPage = lazy(() => import("@pages/Landing/LandingPage"));
const LoginPage = lazy(() => import("@pages/LoginPage"));
const NotFound = lazy(() => import("@pages/NotFound"));
const RegisterPage = lazy(() => import("@pages/RegisterPage"));
const ResetPasswordPage = lazy(() => import("@pages/ResetPasswordPage"));
const DonatePage = lazy(() => import("@features/Donate/DonatePage"));
const LabPage = lazy(() => import("@pages/InstitutionalPortal/Lab/LabPage"));
const HomePage = lazy(() => import("@features/Home/Home"));
const SearchPage = lazy(() => import("@pages/SearchPage/SearchResultsPage"));
const InstitutionSearchPage = lazy(
  () =>
    import("@pages/InstitutionalPortal/LabAndPeopleSearch/LabAndPeopleSearch")
);
const InstitutionalDatabase = lazy(
  () =>
    import(
      "@pages/InstitutionalPortal/InstitutionalDatabase/InstitutionalDatabase"
    )
);

function App() {
  useEffect(() => {
    const pageRoute = window.location.pathname + window.location.search;
    const pageTitle =
      pageRoute.length > 1
        ? pageRoute[1].toUpperCase() + pageRoute.slice(2)
        : pageRoute;
    ReactGA.send({
      hitType: "pageview",
      page: pageRoute,
      title: pageTitle
    });
  }, []);

  return (
    <RedirectProvider>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            preventDuplicate
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            Components={{
              success: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent
            }}
          >
            <BrowserRouter>
              <TSAppBar />
              <Suspense fallback={<div>Loading...</div>}>
                <Box
                  sx={{
                    // due to responsive height of appbar
                    marginTop: {
                      xs: "56px",
                      sm: "64px"
                    }
                  }}
                >
                  <Routes>
                    <Route
                      path='/posts/:postId'
                      element={
                        <ProtectedRoute
                          element={
                            // this is unused
                            <>
                              <CreateButton />
                              <PostPage />
                            </>
                          }
                        />
                      }
                    />
                    <Route
                      path='/login'
                      element={
                        <RedirectProtected
                          requiresGetStarted
                          element={
                            <LayoutWithFooter>
                              <LoginPage />
                            </LayoutWithFooter>
                          }
                        />
                      }
                    />
                    <Route
                      path='/forgot-password'
                      element={
                        <LayoutWithFooter>
                          <ForgotPasswordPage />
                        </LayoutWithFooter>
                      }
                    />
                    <Route
                      path='/reset-password'
                      element={
                        <LayoutWithFooter>
                          <ResetPasswordPage />
                        </LayoutWithFooter>
                      }
                    />
                    <Route
                      path='/register'
                      element={
                        <RedirectProtected
                          requiresGetStarted
                          element={
                            <LayoutWithFooter>
                              <RegisterPage />
                            </LayoutWithFooter>
                          }
                        />
                      }
                    />
                    <Route
                      path='/get-started'
                      element={
                        <LayoutWithFooter>
                          <GetStartedPage />
                        </LayoutWithFooter>
                      }
                    />
                    <Route
                      path='/verify'
                      element={
                        <LayoutWithFooter>
                          <EmailVerificationPage />
                        </LayoutWithFooter>
                      }
                    />
                    <Route
                      path='/about'
                      element={
                        <LayoutWithFooter>
                          <AboutPage />
                        </LayoutWithFooter>
                      }
                    />
                    <Route
                      path='/faqs'
                      element={
                        <LayoutWithFooter>
                          <FAQPage />
                        </LayoutWithFooter>
                      }
                    />
                    <Route
                      path='/'
                      element={
                        <>
                          <LandingPage />
                        </>
                      }
                    />
                    <Route
                      path='/dashboard/*'
                      Component={(props: Record<string, unknown>) => (
                        <>
                          <DashboardRoute
                            component={Portal}
                            redirectUnauthorized
                            {...props}
                          />
                        </>
                      )}
                    >
                      <Route path='home' element={<HomePage />} />
                      <Route path='search' element={<SearchPage />} />
                      <Route path='create' element={<CreatePage />} />
                      <Route path='member/:userId' element={<ProfilePage />} />
                      <Route path='lab' element={<LabPage />} />
                      <Route
                        path='institution-database'
                        element={<InstitutionalDatabase />}
                      />
                      <Route
                        path='institution-search'
                        element={<InstitutionSearchPage />}
                      />
                      <Route path='donate' element={<DonatePage />} />
                      <Route path='*' element={<NotFound dashboard />} />
                    </Route>
                    <Route
                      path='/*'
                      element={
                        <LayoutWithFooter>
                          <NotFound />
                        </LayoutWithFooter>
                      }
                    />
                  </Routes>
                </Box>
              </Suspense>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </React.StrictMode>
    </RedirectProvider>
  );
}

export default App;
