import { auth } from "@features/Auth/authSlice";
import { registerSlice } from "@features/Auth/registerSlice";
import { donateSlice } from "@features/Donate/donateSlice";
import { resourceSlice } from "@features/InsitutionDatabase/resourceSlice";
import { labSlice } from "@features/Lab/labslice";
import { profileSlice } from "@features/Profile/profileSlice";
import { tagSlice } from "@features/Tags/tagSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistAuthConfig = {
  key: "auth",
  storage,
  whitelist: ["user"] // the keys we want to persist
};

const persistRegisterConfig = {
  key: "register",
  storage
};

const persistRegisterReducer = persistReducer(
  persistRegisterConfig,
  registerSlice.reducer
);
const persistedAuthReducer = persistReducer(persistAuthConfig, auth.reducer);

export const rootReducer = combineReducers({
  [auth.name]: persistedAuthReducer,
  [donateSlice.name]: donateSlice.reducer,
  [registerSlice.name]: persistRegisterReducer,
  [profileSlice.name]: profileSlice.reducer,
  [labSlice.name]: labSlice.reducer,
  [tagSlice.name]: tagSlice.reducer,
  [resourceSlice.name]: resourceSlice.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER"
        ]
      }
    });

    // logger middleware only in development environment
    if (process.env.NODE_ENV === "development") {
      return defaultMiddleware.concat(logger);
    }

    return defaultMiddleware;
  }
});

export const persistor = persistStore(store);

// Inferred type: { auth: AuthState }
export type AppDispatch = typeof store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
