import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSortOption,
  setSortOption
} from "@features/InsitutionDatabase/resourceSlice";
import { AppDispatch } from "src/services/store";

const Sort = () => {
  const dispatch: AppDispatch = useDispatch();

  const sortOption = useSelector(selectSortOption);

  const handleSortChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    dispatch(setSortOption(event.target.value));
  };

  return (
    <div className='flex items-center'>
      <hr className='flex-grow border-t border-gray-300' />
      <div className='flex items-center gap-1 ml-2'>
        <span className='text-black'>Sort by: </span>
        <div className='relative'>
          <select
            id='sortOptions'
            value={sortOption}
            onChange={handleSortChange}
            className='block w-full py-2 pr-10 rounded-lg sm:text-sm'
          >
            <option value='recent'>Recent</option>
            <option value='oldest'>Oldest</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Sort;
