import { enqueueSnackbar } from "notistack";

export const handleShare = (resourceId: string) => {
  const resourceUrl = `${window.location.origin}/posts/${resourceId}`;

  navigator.clipboard
    .writeText(resourceUrl)
    .then(() => {
      enqueueSnackbar("Link copied.", { variant: "success" });
    })
    .catch(() => {
      enqueueSnackbar("Sorry, something went wrong.", { variant: "warning" });
    });
};
