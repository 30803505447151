import React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import instagram from "@static/icons/instagram.svg";
import linkedin from "@static/icons/linkedin.svg";
import twitter from "@static/icons/twitter.svg";
import { APP_COLORS } from "src/styles/colors";

const SocialLinks = () => (
  <Stack direction='row' spacing={2} alignItems='center'>
    <Link
      href='https://twitter.com/TissueShare'
      target='_blank'
      underline='none'
    >
      <Box
        sx={{
          padding: 1,
          background: APP_COLORS.white,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img
          style={{ objectFit: "contain", height: 15 }}
          src={twitter}
          alt='Twitter'
        />
      </Box>
    </Link>
    <Link
      href='https://instagram.com/tissueshare'
      target='_blank'
      underline='none'
    >
      <Box
        sx={{
          padding: 1,
          background: APP_COLORS.white,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img
          style={{ objectFit: "contain", height: 15 }}
          src={instagram}
          alt='Instagram'
        />
      </Box>
    </Link>
    <Link
      href='https://www.linkedin.com/company/tissueshare'
      target='_blank'
      underline='none'
    >
      <Box
        sx={{
          padding: 1,
          background: APP_COLORS.white,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img
          style={{ objectFit: "contain", height: 15 }}
          src={linkedin}
          alt='LinkedIn'
        />
      </Box>
    </Link>
  </Stack>
);

export default SocialLinks;
