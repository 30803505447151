import React from "react";
import { Outlet } from "react-router-dom";

import useMatchPath from "@hooks/useMatchPath";
import InstitutionalHome from "@pages/InstitutionalPortal/InstitutionalHomepage";

const Portal = ({ ...props }) => {
  const isInstitutionalHomePath = useMatchPath("/dashboard");

  return isInstitutionalHomePath ? (
    <InstitutionalHome />
  ) : (
    <Outlet {...props} />
  );
};

export default Portal;
