import React, { FC, ReactNode } from "react";

interface DashboardProps {
  children: ReactNode;
}

const Dashboard: FC<DashboardProps> = ({ children }) => {
  return (
    <div className='h-screen-hack flex overflow-hidden bg-white'>
      {children}
    </div>
  );
};

export default Dashboard;
