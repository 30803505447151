import { createTheme } from "@mui/material";

import { APP_COLORS } from "./colors";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    requestContained: true;
    requestOutlined: true;
    shareContained: true;
    shareOutlined: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    custom: Palette["primary"];
  }

  interface PaletteOptions {
    custom?: PaletteOptions["primary"];
  }
}

const color = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: APP_COLORS.primaryMain,
      light: APP_COLORS.primaryLight
    },
    secondary: {
      main: APP_COLORS.secondaryMain,
      light: APP_COLORS.secondaryLight
    },
    info: {
      main: APP_COLORS.infoMain
    },
    custom: {
      main: APP_COLORS.customMain
    },
    background: {
      default: APP_COLORS.backgroundDefault
    }
  },
  typography: {
    fontFamily: "SF-Pro-Rounded, sans-serif"
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.2)"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    }
  }
});

export const theme = createTheme(color, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "requestContained" },
          style: {
            color: APP_COLORS.white,
            borderColor: APP_COLORS.requestColor,
            border: "1px solid transparent",
            backgroundColor: APP_COLORS.requestColor,
            "&:hover": {
              backgroundColor: APP_COLORS.requestColorHover,
              borderColor: APP_COLORS.requestColorHover
            }
          }
        },
        {
          props: { variant: "requestOutlined" },
          style: {
            borderColor: APP_COLORS.shareColor,
            color: APP_COLORS.subtitleColor,
            border: "1px solid",
            "&:hover": {
              color: APP_COLORS.white,
              backgroundColor: APP_COLORS.requestColorHover,
              borderColor: APP_COLORS.requestColorHover
            }
          }
        },
        {
          props: { variant: "shareContained" },
          style: {
            color: APP_COLORS.subtitleColor,
            borderColor: APP_COLORS.shareColor,
            border: "1px solid transparent",
            backgroundColor: APP_COLORS.shareColor,
            "&:hover": {
              backgroundColor: APP_COLORS.shareColorHover,
              borderColor: APP_COLORS.shareColorHover
            }
          }
        },
        {
          props: { variant: "shareOutlined" },
          style: {
            borderColor: APP_COLORS.shareColor,
            color: APP_COLORS.subtitleColor,
            border: "1px solid",
            "&:hover": {
              color: APP_COLORS.subtitleColor,
              backgroundColor: APP_COLORS.shareColorHover,
              borderColor: APP_COLORS.shareColorHover
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          // borderRadius: 8,
          "&:hover": {
            boxShadow: "none"
          },
          "&:active": {
            boxShadow: "none"
          },
          "&:focus": {
            boxShadow: "none"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: color.palette.secondary.light
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: color.palette.secondary.main
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: color.palette.primary.main
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px"
        },
        underline: {
          "&:before": {
            borderBottomColor: color.palette.secondary.light
          },
          "&:hover:before": {
            borderBottomColor: color.palette.secondary.main
          },
          "&.Mui-focused:before": {
            borderBottomColor: color.palette.primary.main
          }
        }
      }
    }
  }
});
