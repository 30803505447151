import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getUser } from "@features/Auth/authSlice";
import CreateIcon from "@mui/icons-material/Create";
import { Fab, Tooltip, Zoom } from "@mui/material";

export default function CreateButton() {
  const currentUser = useSelector(getUser);

  const currentUserId = currentUser.id;
  const isLoggedIn = Boolean(currentUserId !== undefined);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  // Function to update window dimensions
  const updateWindowDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const bottomPos = windowDimensions.height * 0.05;
  const rightPos = windowDimensions.width * 0.05;

  return isLoggedIn ? (
    <Tooltip
      TransitionComponent={Zoom}
      title='Create Post'
      placement='left'
      arrow
    >
      <Fab
        sx={{
          position: "fixed",
          bottom: bottomPos,
          right: rightPos
        }}
        color='primary'
        aria-label='create'
        href='/dashboard/create'
      >
        <CreateIcon />
      </Fab>
    </Tooltip>
  ) : null;
}
