import React, { FC, ReactNode } from "react";

import { clsx } from "@features/utils/clsx";

interface DashboardContentProps {
  children: ReactNode;
  className?: string;
}

const DashboardContent: FC<DashboardContentProps> = ({
  children,
  className
}) => {
  return (
    <main
      id='dashboard-content' // used in entry list infinite scroll
      className='flex-1 relative bg-slate-50 overflow-y-auto focus:outline-none'
      tabIndex={0}
    >
      <div
        className={clsx("max-w-7xl mx-auto p-3 md:p-10 lg:p-20'", className)}
      >
        {children}
      </div>
    </main>
  );
};

export default DashboardContent;
