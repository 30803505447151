import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { APP_COLORS } from "src/styles/colors";

interface ContactFormDialogProps {
  openContactForm: boolean;
  handleCloseContactForm: () => void;
  toUserId?: string;
  postId?: string;
  authorDisplayName: string;
}

interface FormValues {
  message: string;
  postId: string;
  toUserId: string;
}

export default function ContactFormDialog(props: ContactFormDialogProps) {
  function submitContactForm(values: FormValues) {
    fetch("/api/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values)
    })
      .then((response) => {
        if (!response.ok) throw new Error(JSON.stringify(response.json()));
        else return response.json();
      })
      .then(() => {
        enqueueSnackbar(`Successfully emailed ${props.authorDisplayName}!`, {
          variant: "success"
        });
      })
      .catch((error) =>
        enqueueSnackbar("Could not send message: " + error, {
          variant: "warning"
        })
      );
  }

  // Set up formik form variables
  const formik = useFormik({
    initialValues: {
      message: "",
      postId: "",
      toUserId: ""
    },
    onSubmit: (values, { resetForm }) => {
      if (props.postId) values.postId = props.postId;
      if (props.toUserId) values.toUserId = props.toUserId;
      submitContactForm(values);
      props.handleCloseContactForm();
      resetForm();
    }
  });

  return (
    <Dialog
      PaperProps={{ sx: { borderRadius: 1, boxShadow: "none" } }}
      open={props.openContactForm}
      onClose={props.handleCloseContactForm}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle sx={{ color: (theme) => theme.palette.primary.main }}>
        Contact
      </DialogTitle>
      <DialogContent>
        <DialogContentText fontWeight={"bold"}>
          What would you like to ask?
        </DialogContentText>
        <DialogContentText>
          An email will be sent with your message.
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            autoFocus
            margin='dense'
            id='message'
            label={`Message to ${props.authorDisplayName}`}
            value={formik.values.message}
            onChange={formik.handleChange}
            type='text'
            minRows={6}
            maxRows={10}
            multiline
            fullWidth
            inputProps={{ maxLength: 1000 }}
          />
          <DialogActions sx={{ px: 5, pb: 3 }}>
            <Button
              variant='contained'
              sx={{
                bgcolor: APP_COLORS.mediumLightGray,
                "&:hover": { bgcolor: APP_COLORS.secondaryMain }
              }}
              onClick={props.handleCloseContactForm}
            >
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              Send Message
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
