import { useContext } from "react";

import DashboardContext from "@contexts/DashboardContext";

const useSidebar = () => {
  const { sidebar } = useContext(DashboardContext);

  return sidebar;
};

export default useSidebar;
